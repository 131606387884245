import React, { Component, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Textbox from "Components/Textbox/DeprecatedTextbox"
import SmartyStreetsAutoComplete from "Containers/Checkout/SmartyStreetsAutoComplete/SmartyStreetsAutoComplete"
import Dropdown from "Components/Dropdown/DeprecatedDropdown"
import Toggle from "Components/Toggle/Toggle"
import Checkbox from "Components/Checkbox/DeprecatedCheckboxes"
import Typography from "Components/Typography/Typography"
import { UpdatePhoneNumberFormatUsa, PhoneNumberFormatUsaKeyDown } from "Utilities/Utilities"
import { USA_ID, CANADA_ID } from "Constants/Constants"
import InfoIcon from 'Components/InfoIcon/InfoIcon'
import * as SvgIcon from 'Components/SvgIcon/SvgIcon'
import * as ErrorTypes from "Types/ErrorTypes"
import "./AddressFields.css"
import { updateFormValidation, displaySmartyStreetWarnings } from "Actions/checkoutTransactionAction"


const AddressFields = (
    {
        featureFlagContainer,
        countryId,
        formName,
        errors,
        warnings,
        addressId,
        inlineDesktopFormFields,
        showSimpleForm,
        states,
        provinces,
        countries,
        showSignUpCheckBoxes,
        showPoBox,
        updateAddressForm,
        updateFormValidation,
        updateFormAction,
        userProfile,
        form,
        addressValidationErrors,
        displaySmartyStreetWarnings,
        addressHasBeenValidated,
        existingAddresses,
        addressLookup,
        isForPaypalExpress,
        isEditing = false
    }
) => {
    const [leadCaptureImpressionEventSent, setLeadCaptureImpressionEventSent] = useState(false);
    useEffect(() => {
        if (isEditing || (userProfile.zip && userProfile.city && userProfile.region_code)) {
            let state = states.filter(state => state.abbreviation === userProfile.region_code)
            let country = countries.filter(country => country.upsCountryCode === userProfile.country_code)
            let provinceId
            if (state && state.length !== 0) {
                provinceId = state[0].stateProvinceId
            }
            let countryId
            if (country && country.length !== 0) {
                countryId = country[0].countryId
            }

            updateAddressForm(
                {
                    city: form.city || userProfile.city,
                    provinceId: form.provinceId || provinceId,
                    province: form.province || userProfile.region_name,
                    postalCode: form.postalCode || userProfile.zip,
                    email: form.email || userProfile.email,
                    fullName: form.fullName || userProfile.fullName,
                    countryId: countryId || USA_ID,
                    isInternationalAddressToggleActive: (countryId || USA_ID) !== USA_ID,
                })
        }
        else if (!isForPaypalExpress) {
            updateAddressForm(
                {
                    countryId: USA_ID,
                    email: userProfile.email || "",
                    fullName: userProfile.fullName || ""
                })
        }

        if (addressHasBeenValidated && addressValidationErrors && addressValidationErrors.hasSmartyStreetErrors) {
            displaySmartyStreetWarnings(addressValidationErrors)
        }
    }, [userProfile])
    let isSmartyStreetsEnabled = featureFlagContainer ? featureFlagContainer.isSmartyStreetsEnabled : false
    let isUnitedStatesSelected = countryId === USA_ID;
    let selectedCountryHasKnownProvinces = countryId === CANADA_ID;
    let textMessageInfoIcon = <InfoIcon infoIcon={{
        text:
            <><p>By joining by text messages you agree to the following terms. 8 msg/month. Msg & Data Rates May Apply. Text HELP to 773929 for additional info. Text STOP to 773929 to cancel. Text messaging available for US numbers only. </p></>,
        svg: <SvgIcon.MoreInformationSvgIcon className="infoIcon" />
    }}
        gaCategory={"Checkout"}
        name={"Text Message Checkbox"}
    />

    if (showSignUpCheckBoxes && !leadCaptureImpressionEventSent) {
        try {
            let element = `checkout customer info step`
            analytics.track('Lead Capture',
                {
                    lead_type: "Email",
                    action_type: "Impression",
                    element
                })
            analytics.track('Lead Capture',
                {
                    lead_type: "Sms",
                    action_type: "Impression",
                    element
                })

            setLeadCaptureImpressionEventSent(true)
        } catch (err) {
            console.error("failed to send lead capture impression events", err);
        }
    }
    let checkboxes =
        <>
            <p>Keep me up to date on this order as well as special offers from Speedwaymotors.com.  Get $10 off your next order for first time guests.</p>
            <Checkbox
                name="isEmailListCheckboxActive"
                text="Join by Email"
                formName={formName}
                propName="isEmailListCheckboxActive"
                onChange={(event) => updateAddressForm({
                    isEmailListCheckboxActive: event.target.checked
                })} />
            <br />
            {
                featureFlagContainer.checkoutDisplaySmsIterablePreference ? (
                    <>
                        <Checkbox
                            name="isPromotionalTextListCheckboxActive"
                            text="Join by Text - Promotional Messages*"
                            formName={formName}
                            propName="isPromotionalTextListCheckboxActive"
                            onChange={(event) => updateAddressForm({
                                isPromotionalTextListCheckboxActive: event.target.checked
                            })}
                        />
                        <br />
                        <Checkbox
                            name="isTransactionalTextListCheckboxActive"
                            text="Join by Text - Order Messages*"
                            formName={formName}
                            propName="isTransactionalTextListCheckboxActive"
                            onChange={(event) => updateAddressForm({
                                isTransactionalTextListCheckboxActive: event.target.checked
                            })}
                        />
                        <br />
                        <p>
                            *By signing up, you will receive order-based messages and promotional messages.
                            Message frequency varies. Message & data rates may apply.
                            Text HELP to 773929 for additional info.
                            Text STOP to 773929 to cancel. Available for US numbers only.
                        </p>
                    </>
                ) : (
                    <Checkbox
                        name="isTextListCheckboxActive"
                        text="Join by Text Message"
                        formName={formName}
                        propName="isTextListCheckboxActive"
                        infoicon={textMessageInfoIcon}
                        onChange={(event) => updateAddressForm({
                            isTextListCheckboxActive: event.target.checked
                        })}
                    />
                )
            }

        </>

    let phoneInfoIcon = <InfoIcon infoIcon={{
        text:
            <><p>We’ll only call you if there is an issue with your order or if carriers need to reach you about delivery.</p></>,
        svg: <SvgIcon.MoreInformationSvgIcon className="infoIcon" />
    }}
        gaCategory={"Checkout"}
        name={"Phone"}
    />

    let addressTextBox = <Textbox
        type={"text"}
        textbox={{ placeholder: "", header: "Street", isRequired: true }}
        name="addressLine1"
        onChange={(event) => updateAddressForm({ addressLine1: event.target.value }, false)}
        onBlur={(event) => updateFormValidation({ addressLine1: event.target.value })}
        formName={formName}
        propName="addressLine1"
        errorMessages={errors[ErrorTypes.ADDRESS_FORM_ADDRESS_LINE_1]}
        warningMessages={warnings[ErrorTypes.ADDRESS_FORM_ADDRESS_LINE_1]}
        autocomplete={"off"} />

    if (isSmartyStreetsEnabled) {
        addressTextBox = <SmartyStreetsAutoComplete
            textboxDropdown={{
                type: "text",
                textbox: { placeholder: "", header: "Street", isRequired: true },
                name: "addressLine1",
                onChange: (event) => updateAddressForm({ addressLine1: event.target.value }, false),
                formName: formName,
                propName: "addressLine1",
                errorMessages: errors[ErrorTypes.ADDRESS_FORM_ADDRESS_LINE_1],
                warningMessages: warnings[ErrorTypes.ADDRESS_FORM_ADDRESS_LINE_1],
                autocomplete: '',
                onBlur: (event) => {
                    updateFormValidation({ addressLine1: event.target.value });

                    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
                    if (isFirefox) {
                        event.target.setAttribute('autocomplete', 'on');
                    } else {
                        event.target.setAttribute('autocomplete', '');
                    }
                    event.target.setAttribute('name', 'addressLine1');
                },
                onFocus: (event) => {
                    //chrome is ignoring 'off' but nope works? idk your guess is as good as mine
                    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
                    if (isFirefox) {
                        event.target.setAttribute('autocomplete', 'off');
                    } else {
                        event.target.setAttribute('autocomplete', 'nope');
                    }
                    event.target.setAttribute('name', 'notASearchField');
                }
            }}
            updateFormAction={updateFormAction}
            formAddress={form}
        />
    }

    let phoneNumberId = "phoneNumber-" + addressId;

    const validatePhoneNumberFormatUsa = (e, countryId) => {
        if (e && e.target.value && (countryId === USA_ID || countryId === CANADA_ID)) {
            e.target.value = UpdatePhoneNumberFormatUsa(e.target.value)
        }
        updateAddressForm({ phoneNumber: e.target.value, countryId: countryId })
    }

    useEffect(() => {

        const setDefaultAddressProps = () => {

            if (formName != "billingAddressForm") {
                return;
            }

            if (!addressLookup.defaultBillingAddressId) {
                return;
            }

            const defaultAddress = existingAddresses[addressLookup.defaultBillingAddressId]
            if (!defaultAddress) {
                return;
            }
            updateAddressForm(defaultAddress)
        }
        setDefaultAddressProps();

    }, [])


    return (
        <div className={`addressForm ${inlineDesktopFormFields ? "inlineFormFields" : ""}`}>
            <input className="addressId"
                name="addressId"
                type="hidden"
                defaultValue={addressId} />
            {showSimpleForm
                ? false
                : <>
                    <Textbox
                        className="emailTextbox"
                        type="email"
                        textbox={{ header: "Email", isRequired: true }}
                        name="email"
                        onChange={(event) => updateAddressForm({ email: event.target.value }, false)}
                        formName={formName}
                        propName="email"
                        autocomplete="email"
                        errorMessages={errors[ErrorTypes.ADDRESS_FORM_EMAIL]}
                        warningMessages={warnings[ErrorTypes.ADDRESS_FORM_EMAIL]}
                        onBlur={(event) => updateFormValidation({ email: event.target.value })} />

                    <Textbox
                        id={phoneNumberId}
                        className="phoneTextbox"
                        type={countryId === USA_ID || countryId === CANADA_ID ? 'tel' : 'internationalTel'}
                        textbox={{ header: "Phone Number", isRequired: true, infoIcon: phoneInfoIcon }}
                        name="phoneNumber"
                        onChange={(event) => {
                            validatePhoneNumberFormatUsa(event, countryId)
                        }}
                        onBlur={(event) => {
                            updateFormValidation({ phoneNumber: event.target.value, countryId: countryId })
                        }}
                        formName={formName}
                        propName="phoneNumber"
                        autocomplete="tel"
                        errorMessages={errors[ErrorTypes.ADDRESS_FORM_PHONE]}
                        warningMessages={warnings[ErrorTypes.ADDRESS_FORM_PHONE]}
                    />
                    {showSignUpCheckBoxes ? checkboxes : false}
                    <Typography as={'h3'} size={'h4'}>Ship to</Typography>
                </>
            }
            <Textbox
                type="text"
                textbox={{ placeholder: "", header: "Full Name", isRequired: true }}
                name="fullName"
                onChange={(event) => updateAddressForm({ fullName: event.target.value })}
                formName={formName}
                propName="fullName"
                autocomplete="name"
                errorMessages={errors[ErrorTypes.ADDRESS_FORM_FULL_NAME]}
                warningMessages={warnings[ErrorTypes.ADDRESS_FORM_FULL_NAME]}
                onBlur={(event) => updateFormValidation({ fullName: event.target.value })}
            />
            {addressTextBox}
            <OptionalAddressFieldsToggle
                formName={formName}
                updateAddressForm={updateAddressForm}
                updateFormValidation={updateFormValidation}
                errors={errors}
                warnings={warnings}
                isOpen={form.company || form.addressLine2 || form.isCommercial ? true : false} />
            <CountryDropdownInput
                formName={formName}
                countries={countries}
                updateAddressForm={updateAddressForm}
                updateFormValidation={updateFormValidation}
                errors={errors}
                warnings={warnings}
            />
            <Textbox
                className="cityTextbox"
                type="text"
                textbox={{ placeholder: "", header: "City", isRequired: true }}
                name="city"
                onChange={(event) => updateAddressForm({ city: event.target.value }, false)}
                formName={formName}
                propName="city"
                errorMessages={errors[ErrorTypes.ADDRESS_FORM_CITY]}
                warningMessages={warnings[ErrorTypes.ADDRESS_FORM_CITY]}
                onBlur={(event) => updateFormValidation({ city: event.target.value })}
            />
            <StateOrProvinceInput
                isUnitedStatesSelected={isUnitedStatesSelected}
                formName={formName}
                options={isUnitedStatesSelected ? states : (selectedCountryHasKnownProvinces ? provinces : null)}
                updateAddressForm={updateAddressForm}
                updateFormValidation={updateFormValidation}
                errors={errors}
                warnings={warnings}
            />
            <Textbox
                className="zipTextbox"
                type={isUnitedStatesSelected ? "number" : "text"}
                textbox={{ header: isUnitedStatesSelected ? "Zip Code" : "Postal Code", isRequired: true, hideSpinner: isUnitedStatesSelected, maxLength: isUnitedStatesSelected ? 5 : null }}
                name="postalCode"
                onChange={(event) => updateAddressForm({ postalCode: event.target.value }, false)}
                formName={formName}
                propName="postalCode"
                errorMessages={errors[ErrorTypes.ADDRESS_FORM_POSTAL_CODE]}
                warningMessages={warnings[ErrorTypes.ADDRESS_FORM_POSTAL_CODE]}
                onBlur={(event) => updateFormValidation({ postalCode: event.target.value, countryId: countryId })}
            />
        </div>
    )
}

const CountryDropdownInput = connect((state, ownProps) => {
    return {
        phoneNumber: state.transaction[ownProps.formName]["phoneNumber"],
        countryId: state.transaction[ownProps.formName]["countryId"],
    }
}, null)(CountryDropdownInputInternal)

function CountryDropdownInputInternal(props) {
    return (
        <>
            <Dropdown
                dropdown={{
                    header: "Country",
                    name: "country",
                    options: props.countries
                        ? props.countries.map(option => ({ key: option.countryId, value: option.countryId, text: option.countryName }))
                        : false,
                    emptyOptionString: "Pick Your Country",
                    isRequired: true
                }}
                formName={props.formName}
                propName="countryId"
                onChange={(event) => {
                    const selectedCountryId = event.target.value;
                    props.updateAddressForm({ countryId: parseInt(selectedCountryId), phoneNumber: props.phoneNumber }, false);
                    props.updateFormValidation({ countryId: parseInt(selectedCountryId) });
                }}
                name="country"
                errorMessages={props.errors[ErrorTypes.ADDRESS_FORM_COUNTRYID]}
                warningMessags={props.warnings[ErrorTypes.ADDRESS_FORM_COUNTRYID]}
            />
        </>

    )
}

function StateOrProvinceInput(props) {
    return (<>
        {props.isUnitedStatesSelected
            ? <Dropdown
                className="stateDropdown"
                dropdown={{
                    header: "State",
                    options: props.options
                        ? props.options.map(option => ({ key: option.stateProvinceId, value: option.stateProvinceId, text: option.name }))
                        : false,
                    name: "state",
                    emptyOptionString: "Pick Your State",
                    isRequired: true
                }}
                formName={props.formName}
                propName="provinceId"
                onChange={(event) => {
                    props.updateFormValidation({ provinceId: parseInt(event.target.value) })
                    props.updateAddressForm({ provinceId: parseInt(event.target.value) }, false)
                }}
                errorMessages={props.errors[ErrorTypes.ADDRESS_FORM_PROVINCE]}
                warningMessages={props.warnings[ErrorTypes.ADDRESS_FORM_PROVINCE]} />
            : props.options && props.options.length
                ? <Dropdown
                    className="stateDropdown"
                    dropdown={{
                        header: "Province",
                        options: props.options
                            ? props.options.map(option => ({ key: option.stateProvinceId, value: option.stateProvinceId, text: option.name }))
                            : false,
                        name: "province",
                        emptyOptionString: "Pick Your Province",
                        isRequired: true
                    }}
                    formName={props.formName}
                    propName="provinceId"
                    onChange={(event) => {
                        props.updateFormValidation({ provinceId: parseInt(event.target.value) })
                        props.updateAddressForm({ provinceId: parseInt(event.target.value) }, false)
                    }}
                    errorMessages={props.errors[ErrorTypes.ADDRESS_FORM_PROVINCE]}
                    warningMessages={props.warnings[ErrorTypes.ADDRESS_FORM_PROVINCE]} />
                : <Textbox
                    className="provinceTextbox"
                    type="text"
                    textbox={{ placeholder: "", header: "Province", isRequired: true }}
                    name="province"
                    onChange={(event) => props.updateAddressForm({ province: event.target.value }, false)}
                    formName={props.formName}
                    propName="province"
                    onBlur={(event) => props.updateFormValidation({ province: event.target.value })}
                    errorMessages={props.errors[ErrorTypes.ADDRESS_FORM_PROVINCE]}
                    warningMessages={props.warnings[ErrorTypes.ADDRESS_FORM_PROVINCE]} />}
    </>)
}

function OptionalAddressFieldsToggle(props) {
    const [toggleOpen, setToggleOpen] = useState(false);

    useEffect(() => {
        if (props.isOpen) {
            setToggleOpen(true)
        }
    }, [props.isOpen])

    let addressToggleContent = (
        <>
            <Textbox
                type="text"
                textbox={{ placeholder: "", header: "Apt, Suite Number", isRequired: false }}
                name="addressLine2"
                onChange={(event) => props.updateAddressForm({ addressLine2: event.target.value }, false)}
                formName={props.formName}
                propName="addressLine2"
                errorMessages={props.errors[ErrorTypes.ADDRESS_FORM_ADDRESS_LINE_2]}
                warningMessages={props.warnings[ErrorTypes.ADDRESS_FORM_ADDRESS_LINE_2]}
                onBlur={(event) => props.updateFormValidation({ addressLine2: event.target.value })} />


            <Textbox
                type="text"
                textbox={{ placeholder: "", header: "Company", isRequired: false }}
                name="company"
                onChange={(event) => props.updateAddressForm({ company: event.target.value }, false)}
                formName={props.formName}
                propName="company"
                errorMessages={props.errors[ErrorTypes.ADDRESS_FORM_COMPANY]}
                warningMessages={props.warnings[ErrorTypes.ADDRESS_FORM_COMPANY]}
                onBlur={(event) => props.updateFormValidation({ company: event.target.value })} />
        </>
    )
    let addressOpenToggleHandle = (
        <>
            <a href="javascript:void(0);" aria-label="Hide Apartment Suite or Company Information" aria-expanded="true">Add Apartment, Suite, Company, etc.</a>
            <p className="togglePlusMinus">-</p>
        </>
    )
    let addressClosedToggleHandle = (
        <>
            <a href="javascript:void(0);" aria-label="Show Apartment Suite or Company Information" aria-expanded="false">Add Apartment, Suite, Company, etc.</a>
            <p className="togglePlusMinus">+</p>
        </>
    )
    let addressToggle = {
        openToggleHandle: addressOpenToggleHandle,
        closedToggleHandle: addressClosedToggleHandle,
        toggleableContent: addressToggleContent,
        isActive: props.errors[ErrorTypes.ADDRESS_FORM_ADDRESS_LINE_2] ||
            props.errors[ErrorTypes.ADDRESS_FORM_COMPANY] ||
            props.warnings[ErrorTypes.ADDRESS_FORM_ADDRESS_LINE_2] ||
            props.warnings[ErrorTypes.ADDRESS_FORM_COMPANY]
            ? true : toggleOpen
    }

    return (
        <Toggle toggle={addressToggle} onClick={() => setToggleOpen(
            props.errors[ErrorTypes.ADDRESS_FORM_ADDRESS_LINE_2] ||
                props.errors[ErrorTypes.ADDRESS_FORM_COMPANY] ||
                props.warnings[ErrorTypes.ADDRESS_FORM_ADDRESS_LINE_2] ||
                props.warnings[ErrorTypes.ADDRESS_FORM_COMPANY]
                ? true : !toggleOpen)} />
    )
}

const mapDispatchToProps = {
    updateFormValidation,
    displaySmartyStreetWarnings
}

const mapStateToProps = (state, ownProps) => {
    return {
        featureFlagContainer: state.featureFlagContainer,
        countries: state.transaction.addressForm.countries,
        states: state.transaction.addressForm.states,
        provinces: state.transaction.addressForm.provinces,
        errors: state.transaction.errors,
        warnings: state.transaction.warnings,
        countryId: state.transaction[ownProps.formName]["countryId"],
        addressId: state.transaction[ownProps.formName]["addressId"],
        userProfile: state.userProfile,
        form: state.transaction[ownProps.formName],
        addressValidationErrors: state.transaction.addressValidationErrors,
        addressHasBeenValidated: state.transaction.addressHasBeenValidated,
        existingAddresses: state.transaction.addresses,
        addressLookup: state.transaction.addressLookup
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressFields)