import classNames from 'classnames';
import { connect } from 'react-redux'
import React, { PureComponent } from 'react';
import { withRouter } from "react-router-dom";
import * as queryString from 'query-string';
import "./ProgressBar.css";
import { updateShippingAddress, updateAddressBookDisplay } from 'Actions/checkoutTransactionAction'


class ProgressBar extends PureComponent {
    constructor(props) {
        super(props)
        this.handleAddressClick = this.handleAddressClick.bind(this)
        this.handleShippingClick = this.handleShippingClick.bind(this)
    }

    render() {

        const classes = classNames(
            "progressBar",
            this.props.step ? "progressBar-" + this.props.step : null
        );

        let address;
        let shipping;
        if (this.props.step === "customerInfo") {
            address = (<div className="address" onClick={this.handleAddressClick}>Address</div>);
            shipping = (<div className="shipping" onClick={this.handleShippingClick}>Shipping</div>);
        } else if (this.props.step === "shipping") {
            address = (<div className="address"><a href="javascript:void(0);" onClick={this.handleAddressClick} aria-label="Go Back to Address Step">Address</a></div>);
            shipping = (<div className="shipping" onClick={this.handleShippingClick}>Shipping</div>);
        } else if (this.props.step === "payment") {
            address = (<div className="address"><a href="javascript:void(0);" onClick={this.handleAddressClick} aria-label="Go Back to Address Step">Address</a></div>);
            shipping = (<div className="shipping"><a href="javascript:void(0);" onClick={this.handleShippingClick} aria-label="Go Back to Shipping Step">Shipping</a></div>);
        }

        return (
            <div className={classes}>
                <div className="firstLine"></div>
                <div className="firstPoint"></div>
                {address}
                <div className="addressClick" onClick={this.handleAddressClick}></div>
                <div className="secondLine"></div>
                <div className="secondPoint"></div>
                {shipping}
                <div className="shippingClick" onClick={this.handleShippingClick}></div>
                <div className="thirdPoint"></div>
                <div className="payment">Payment</div>
            </div>
        )
    }

    handleAddressClick(e) {

        if (this.props.step === "shipping") {
            this.props.updateAddressBookDisplay(true)
        }
        else if (this.props.step == "payment") {
            let shippingPage = "/buy/shipping/?transactionId=" + this.props.transactionId

            this.props.history.push(shippingPage)
            this.props.updateAddressBookDisplay(true)
        }
    }

    handleShippingClick(e) {
        if (this.props.step === "payment") {
            this.props.history.push("/buy/shipping/?transactionId=" + this.props.transactionId)
        }
    }
}

const mapDispatchToProps = {
    updateAddressBookDisplay
}

const mapStateToProps = state => {
    return {
        transactionId: state.transaction.transactionId
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProgressBar))