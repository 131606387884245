import React, { Fragment } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import './CheckoutFooter.css'
import StickyCheckoutFooter from 'Containers/Checkout/StickyCheckoutFooter/StickyCheckoutFooter'
import * as SvgIcon from 'Components/SvgIcon/SvgIcon'

const CheckoutFooter = ({ variant, isFooterRedesignEnabled }) => {
    let footerClass
    let stickyFooter = false

    if (false && variant) {
        footerClass = 'stickyFooterEnabled'
        stickyFooter = <StickyCheckoutFooter variant={variant} />
    }

    return (
        <Fragment>
            {!isFooterRedesignEnabled ? (
                <footer id="footer" className={classNames(footerClass, 'footer')}>
                    <div className="contacts">
                        <div id="needHelp">Need Help?</div>
                        <div id="contactUs">
                            <span>Contact us: </span>
                            <a href="tel:1-800-979-0122">1.800.979.0122</a>
                        </div>
                    </div>
                    <div id="termsAndPolicy">
                        <a href="/info/termsofuse" title="Terms of Use">
                            Terms of Use
                        </a>
                        <span> | </span>
                        <a href="/info/privacypolicy" title="Privacy Policy">
                            Privacy Policy
                        </a>
                        <div>© 2019 Speedway Motors, Inc. All Rights Reserved</div>
                    </div>
                    <div id="footerLegalIcons" className="hideMobile">
                        <div className="iconContainer">
                            <a
                                href="https://www.mcafeesecure.com/RatingVerify?ref=www.speedwaymotors.com"
                                title="McAfee"
                                target="_blank"
                                rel="noopener"
                            >
                                <SvgIcon.McAfeeSvgIcon />
                            </a>
                        </div>
                        <div
                            className="iconContainer"
                            title="BBB"
                            onClick={() =>
                                window.open('/BBB', 'Speedway Motors BBB', 'width=620, height=250')
                            }
                        >
                            <SvgIcon.BBBSvgIcon />
                        </div>
                    </div>
                </footer>
            ) : null}
            {stickyFooter}
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        isFooterRedesignEnabled: state.featureFlagContainer.isFooterRedesignEnabled
    }
}

export default connect(mapStateToProps)(CheckoutFooter)
