import React, { lazy, Suspense, useState } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import * as SvgIcon from "Components/SvgIcon/SvgIcon"
import './orderDetails.css';
import Typography from "Components/Typography/Typography"
import Divider from "Components/Divider/Divider"
import Toggle from "Components/Toggle/Toggle"
import { CardPrimary, CardSecondary } from "Components/Card/Card"
import Button from "Components/Button/Button"
import InfoIcon from "Components/InfoIcon/InfoIconV2"
import { enableLoader } from 'Actions/checkoutTransactionAction';
import { sendGoogleAnalyticsEvent } from 'Actions/googleAnalyticsAction';
import { enterCheckout } from 'Actions/shoppingCartAction';
import LoyaltyPointsPreview from "Containers/Checkout/Loyalty/PointsPreview"

const PayPalExpressButton = lazy(() => { return import('Containers/Checkout/PayPalExpressButton/PayPalExpressButton') });
const GiftCardPromoForm = lazy(() => { return import('Containers/Checkout/GiftCardPromoForm/GiftCardPromoForm') });
const LoyaltyCodeForm = lazy(() => { return import('Containers/Checkout/LoyaltyCodeForm/LoyaltyCodeForm') });
const Discounts = lazy(() => { return import('Containers/Checkout/Discounts/Discounts') });

const OrderDetails = ({ orderDetails, orderHeaderTitle = "Order", isOrderSummaryToggleActive, showProceedFromShoppingCart, showPaypalExpressButton, handleContinueToCheckoutClick }) => {
    let isMobile = document.body.clientWidth < 901
    const [isOrderSummaryActive, toggleOrderSummary] = useState(isOrderSummaryToggleActive && !isMobile)

    let orderTotalsDisplayStyle = orderDetails.futureItemCount > 0 && orderDetails.itemCount > 0 ? " closedBackorderedTotal" : "";

    let orderSummaryOpenToggleHandle = (
        <>
            <span>Hide Order Summary</span>
            <SvgIcon.DownArrowSvgIcon className="invert" />
        </>
    )
    let orderSummaryClosedToggleHandle = (
        <>
            <span>Show Order Summary</span>
            <SvgIcon.DownArrowSvgIcon className="down" />
        </>
    )

    let orderSummaryToggle = {
        openToggleHandle: orderSummaryOpenToggleHandle,
        closedToggleHandle: orderSummaryClosedToggleHandle,
        toggleableContent: <OrderDetailsSummary isClosed={false} orderDetails={orderDetails} showProceedFromShoppingCart={showProceedFromShoppingCart} />,
        isActive: isOrderSummaryActive
    }
    return (
        <CardPrimary id="orderSummaryContainer" className="sticky">
            <Typography as={'h2'} className="orderSummaryHeading">{orderHeaderTitle}</Typography>
            <div className="orderSummaryToggle">
                <Toggle onClick={() => toggleOrderSummary(!isOrderSummaryActive)} toggle={orderSummaryToggle}></Toggle>
            </div>
            <div onClick={() => toggleOrderSummary(!isOrderSummaryActive)} className={"closedTotal" + orderTotalsDisplayStyle}>
                {!isOrderSummaryActive ? <OrderDetailsSummary isClosed={true} orderDetails={orderDetails}  /> : false}
            </div>
            {showProceedFromShoppingCart ?
                <>
                    <Divider variant="horizontal" />
                    <Button variant={'confirmation'} className="proceedToCheckoutBttn" onClick={() => { handleContinueToCheckoutClick(orderDetails.lineItemsSubtotal) }}>
                        <SvgIcon.LockSvgIcon />
                        Proceed to Secure Checkout
                    </Button>
                    {showPaypalExpressButton ?
                        <Suspense fallback={<div></div>}><PayPalExpressButton isMvcRedirect={true} /></Suspense> : false
                    }
                </> : false}
        </CardPrimary>
    )
}

const OrderDetailsSummary = ({ isClosed, orderDetails, showProceedFromShoppingCart }) => {
    let isBackorderedItemInCart = orderDetails.itemCount > 0 && orderDetails.futureItemCount > 0
    let orderTitle = isBackorderedItemInCart ? "Current Charges" : "Order Details"
    let totalText = isBackorderedItemInCart ? "Current Total" : "Total"
    let futureChargesInfoIcon = <InfoIcon infoIcon={{
        text: 'You will not be charged for backordered items until they ship. There will be no additional ' +
            "shipping charges at that time for domestic orders.", svg: < SvgIcon.MoreInformationSvgIcon className={"infoIcon"} />
    }}
        gaCategory={"Checkout"}
        name={"Future Charges"}
    />

    if (isClosed) {
        orderTitle = orderDetails.futureItemCount === 0 ? "" : orderTitle

        return (
            <>
                <MinOrderDetail title={orderTitle} itemCount={orderDetails.itemCount} charges={orderDetails.currentAmountDue} />
                <MinOrderDetail title={"Future Charges"} itemCount={orderDetails.futureItemCount} charges={orderDetails.futurePostDiscountTotalPrice} />
            </>
        )
    }

    return (
        <>
            <OrderDetail title={orderTitle} itemCount={orderDetails.itemCount} lineItemsSubtotal={orderDetails.lineItemsSubtotal} shippingPrice={orderDetails.shippingPrice} estimatedSalesTax={orderDetails.estimatedSalesTax}
                retailDeliveryFee={orderDetails.retailDeliveryFee} currentAmountDue={orderDetails.currentAmountDue} futureInfoIcon={<span />} titleClassName={"orderDetailsTitle"} promos={orderDetails.currentPromos} preDiscountTotalPrice={orderDetails.totalPricePreDiscount}
                totalText={totalText} giftCards={orderDetails.currentGiftCards} showProceedFromShoppingCart={showProceedFromShoppingCart}
                loyaltyRedemption={orderDetails.currentLoyaltyRedemption} shippingProtectionOptions={orderDetails.currentShippingProtectionOptions} />
            <OrderDetail title={"Future Charges"} itemCount={orderDetails.futureItemCount} lineItemsSubtotal={orderDetails.futureLineItemsSubtotal} shippingPrice={orderDetails.futureShippingPrice} estimatedSalesTax={orderDetails.futureEstimatedSalesTax}
                retailDeliveryFee={orderDetails.futureRetailDeliveryFee} currentAmountDue={orderDetails.futurePostDiscountTotalPrice} futureInfoIcon={futureChargesInfoIcon} titleClassName={"futureOrderTitle"} promos={orderDetails.futurePromos} preDiscountTotalPrice={orderDetails.futurePreDiscountTotalPrice}
                totalText={"Future Total"} showProceedFromShoppingCart={showProceedFromShoppingCart} loyaltyRedemption={orderDetails.futureLoyaltyRedemption} giftCards={orderDetails.futureGiftCards} />
            <LoyaltyPointsPreview />
        </>
    )
}

const MinOrderDetail = ({ itemCount, title, charges }) => {
    if (itemCount <= 0) {
        return false;
    }

    let TitleSection = ({ titleText }) => {
        if (!titleText) {
            return false
        }

        return (
            <div className={"titleText"}>
                <Typography>{title}</Typography>
            </div>
        )
    }

    return (
        <>
            <div className={"closedDetail"}>
                <TitleSection titleText={title} />
                <div className={"chargeValue"}>
                    <Typography>{charges}</Typography>
                </div>
            </div>
        </>
    )
}

const OrderDetail = ({ title, itemCount, lineItemsSubtotal, shippingPrice, estimatedSalesTax, retailDeliveryFee, currentAmountDue, futureInfoIcon, titleClassName, promos, preDiscountTotalPrice, totalText, giftCards, showProceedFromShoppingCart, loyaltyRedemption, shippingProtectionOptions }) => {
    if (itemCount < 1) {
        return false
    }
    return (
        <>
            <div className={titleClassName}>
                <Typography size={'h4'}>{title}</Typography>
                {futureInfoIcon}
            </div>
            <div className={"orderDetail"}>
                <div className={"orderDetailText"}>
                    Items ({itemCount})
                </div>
                <div className={"orderDetailValue"}>
                    {lineItemsSubtotal}
                </div>
            </div>
            <div className={"orderDetail"}>
                <div className={"orderDetailText"}>
                    Shipping
                </div>
                <div className={"orderDetailValue"}>
                    {shippingPrice}
                </div>
            </div>
            <div>
                {shippingProtectionOptions?.map(option => {
                    return (
                        <CardSecondary className="shippingProtectionDisplay" key={option.shippingOptionId}>
                            <>
                                <div className="shippingProtectionContents">
                                    <div className="shippingProtectionInformation" >
                                        <p data-testid={`${title}_ShippingProtectionOption_SelectedOption_Label`} className="shippingProtectionTitle">{option.title}</p>
                                    </div>
                                    <p className="shippingProtectionPrice" data-testid={`${title}_ShippingProtectionOption_SelectedOption_Price`}>
                                        {option.priceString === '$0.00' ? 'Included' : option.priceString}
                                    </p>
                                </div>
                            </>
                        </CardSecondary>
                    )
                }
                )}

            </div>

            {showProceedFromShoppingCart !== true ?

                <Suspense fallback={<div></div>}><Discounts
                    promotions={{
                        promotions: promos,
                        alternateDisplay: true
                    }}
                    giftCards={{
                        giftCards: giftCards,
                        alternateDisplay: true
                    }}
                    loyaltyRedemption={{
                        loyaltyRedemption: loyaltyRedemption,
                        alternateDisplay: true
                    }} /></Suspense>
                : false}

            <div className={"orderDetail"}>
                <div className={"orderDetailText"}>
                    Sales Tax
                </div>
                <div className={"orderDetailValue"}>
                    {estimatedSalesTax}
                </div>
            </div>
            {retailDeliveryFee && parseFloat(retailDeliveryFee.slice(1)) !== 0 &&
                <div className={"orderDetail"}>
                    <div className={"orderDetailText"}>
                        Retail Delivery Fee
                    </div>
                    <div className={"orderDetailValue"}>
                        {retailDeliveryFee}
                    </div>
                </div>
            }
            <Divider variant="horizontal" />
            <StrikeThroughPrice preDiscountPrice={preDiscountTotalPrice} currentAmountDue={currentAmountDue} />
            <div className={"orderDetailTotal"}>
                <div className={"orderDetailTotalText"}>
                    <Typography size={'h4'}> {totalText}</Typography>
                </div>
                <div className={"orderDetailTotalValue"}>
                    <Typography size={'h4'}>{currentAmountDue}</Typography>
                </div>
            </div>
        </>
    )
}

const StrikeThroughPrice = ({ preDiscountPrice, currentAmountDue }) => {
    if (!(preDiscountPrice && currentAmountDue !== preDiscountPrice)) {
        return false;
    }

    return <Typography as={'h6'} className={"strikeThroughPrice"}>preDiscountPrice</Typography>
}

const handleContinueToCheckoutClick = (orderPrice) => (dispatch) => {
    dispatch(enableLoader())
    window.location.href = "/buy"
    if (!navigator.globalPrivacyControl && !document.cookie.includes('EnableGpc=')) {
        dispatch(sendGoogleAnalyticsEvent('Cart', 'Advanced To Checkout', 'Order Summary'))
    }
    dispatch(enterCheckout(orderPrice))
}

const mapDispatchToProps = {
    handleContinueToCheckoutClick
};

const mapStateToProps = (state) => {

    return {
        orderDetails: state.orderDetails
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderDetails));
