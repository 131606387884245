import {withRouter} from "react-router-dom"
import {connect} from 'react-redux'
import React from 'react'
import Button from 'Components/Button/Button';
import * as SvgIcon from "Components/SvgIcon/SvgIcon"
import Typography from 'Components/Typography/Typography'
import {submitCustomerInfoStep, submitShippingMethodStep,} from "Actions/checkoutTransactionAction"
import './stickyCheckoutFooter.css'

const StickyCheckoutFooter = ({
    variant,
    shippingMethodContainer,
    submitShippingMethodStep,
    submitCustomerInfoStep,
    isStickyFooterEnabled,
    orderDetails,
    ...props
}) => {

    let buttonMessage;
    let onclick
    if (variant === "shipping") {
        buttonMessage = "Enter Payment Method"
        onclick = handleContinueToPaymentClick(shippingMethodContainer, submitShippingMethodStep, props.history)
    }
    if (variant === "customerInfo") {
        buttonMessage = "Select Shipping Method"
        onclick = handleSubmitGuestCheckoutClick(submitCustomerInfoStep, props.history)
    }
    if (variant === "cart") {
        buttonMessage = (
            <div>
                <SvgIcon.LockSvgIcon />
                Proceed to Secure Checkout
            </div>
        )
        onclick = handleSubmitGuestCheckoutClick(submitCustomerInfoStep, props.history)
    }
return (
        <>
            <div className="stickyFooterPadding" />
            <div className="stickyCheckoutFooterContainer">
                <div className="stickyCheckoutFooter">
                    <Typography size={"h2"} className="currentAmountDueFooter">Total: {orderDetails.currentAmountDue != '$0.00' ? orderDetails.currentAmountDue : orderDetails.futurePostDiscountTotalPrice }</Typography>
                    <Button variant={'confirmation'} className="footerBttn" onClick={onclick}>
                        {buttonMessage}
                    </Button> 
                </div>
            </div>
        </>
    )
}

const handleContinueToCheckoutClick = () => (dispatch, history) => {
    dispatch(enableLoader())
    window.location.href = "/buy"
    if(!navigator.globalPrivacyControl && !document.cookie.includes('EnableGpc=')) {
        dispatch(sendGoogleAnalyticsEvent('Cart', 'Advanced To Checkout', 'Order Summary'))
    }
}


//customerinfo (step 1) submit button action
export const handleSubmitGuestCheckoutClick = (submitCustomerInfo, history) => (dispatch) => {

    let callbackWrapper = function (history, submitCustomerInfo, callback) {
        submitCustomerInfo(history);
        callback();
    }
    let changeFocusToAnyErrors = function () {
        let erroredElements = document.getElementById("guestCheckoutContainer").querySelectorAll(".hasError")

        if (erroredElements && erroredElements.length) {
            if (erroredElements[0].querySelectorAll('input')[0]) {
                erroredElements[0].querySelectorAll('input')[0].focus();
            } else {
                erroredElements[0].querySelectorAll('select')[0].focus();
            }
        }
    }

    callbackWrapper(history, submitCustomerInfo, changeFocusToAnyErrors);
}


//shipping page (step 2) submit button action
export const handleContinueToPaymentClick = (shippingMethodContainer, submitShippingMethodStep, history) => (dispatch) => {


    const { shipments } = shippingMethodContainer
    const optionSelected = shipments.filter(x => x.displayedShippingOptions.find(y => y.isSelected === true) || x.hiddenShippingOptions.find(y => y.isSelected === true) || x.inStorePickupOptions.find(y => y.isSelected === true))
    if (optionSelected.length < shipments.length) {
        alert("Please select a shipping option for all shipments")
        return
    }


    const selectedShippingOptions = shipments.filter(shipment => shipment.shipmentTag.includes("standard")).map(x => {
        let selectedDisplayedShippingOption = x.displayedShippingOptions.find(y => y.isSelected === true)
        let selectedHiddenShippingOption = x.hiddenShippingOptions.find(y => y.isSelected === true)
        let selectedShippingOptionId = null

        if (selectedDisplayedShippingOption || selectedHiddenShippingOption) {

            if (selectedDisplayedShippingOption) {
                selectedShippingOptionId = selectedDisplayedShippingOption.shippingMethodId
            } else if (selectedHiddenShippingOption) {
                selectedShippingOptionId = selectedHiddenShippingOption.shippingMethodId
            }

            // TODO: We need to handle this better, check all shipments for an unselected option, and exit with error.
            if (selectedShippingOptionId === null) {
                alert("Please select a shipping option for all shipments")
                return null
            }
            let inStorePickupOption

            if (selectedDisplayedShippingOption !== undefined) {
                inStorePickupOption = selectedDisplayedShippingOption.inStorePickupOptions ? selectedDisplayedShippingOption.inStorePickupOptions.find(option => option.isSelected) : null
            }

            return {
                ShipmentId: x.shipmentId,
                ShippingOptionId: selectedShippingOptionId,
                InStorePickupLocationId: inStorePickupOption ? inStorePickupOption.inStorePickupId : null,
                InStorePickupDate: inStorePickupOption ? inStorePickupOption.pickupReadyDate : null,
                TransactionId: null
            }
        }
    });

    submitShippingMethodStep(selectedShippingOptions, history)
}

const mapDispatchToProps = {
    handleContinueToCheckoutClick,
    submitShippingMethodStep,
    submitCustomerInfoStep
};

const mapStateToProps = (state) => {

    return {
        orderDetails: state.orderDetails,
        shippingMethodContainer: state.transaction.shippingMethodContainer
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StickyCheckoutFooter));