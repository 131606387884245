import React, { Component, lazy, Suspense } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import * as SvgIcon from "Components/SvgIcon/SvgIcon"
import './orderDetails.css';
import Typography from "Components/Typography/Typography"
import Divider from "Components/Divider/Divider"
import Toggle from "Components/Toggle/Toggle"
import { CardPrimary } from "Components/Card/Card"
import Button from "Components/Button/Button"
import InfoIcon from "Components/InfoIcon/InfoIcon"
import { enableLoader } from 'Actions/checkoutTransactionAction';
import { CardSecondary } from "Components/Card/Card"
import LoyaltyPointsPreview from "Containers/Checkout/Loyalty/PointsPreview"
import { sendGoogleAnalyticsEvent } from 'Actions/googleAnalyticsAction';
import { enterCheckout } from 'Actions/shoppingCartAction';

const PayPalExpressButton = lazy(() => { return import('Containers/Checkout/PayPalExpressButton/PayPalExpressButton') });
const GiftCardPromoForm = lazy(() => { return import('Containers/Checkout/GiftCardPromoForm/GiftCardPromoForm') });
const LoyaltyCodeForm = lazy(() => { return import('Containers/Checkout/LoyaltyCodeForm/LoyaltyCodeForm') });
const Discounts = lazy(() => { return import('Containers/Checkout/Discounts/Discounts') });


class OrderDetails extends Component {
    constructor(props) {
        super(props)
        //don't use this query any where else unless you are very sure that you want the performance hit (speak with joel or derek)
        let isMobile = document.body.clientWidth < 901
        this.state = {
            isOrderSummaryToggleActive: this.props.isOrderSummaryToggleActive && !isMobile

        }
        this.handleToggle = this.handleToggle.bind(this)
    }

    handleToggle() {
        this.setState({ isOrderSummaryToggleActive: !this.state.isOrderSummaryToggleActive })
    }
    render() {
        const { orderDetails, isCustomerInfo, orderHeaderTitle = "Order" } = this.props
        let giftCardsData = {
            giftCards: orderDetails.giftCards,
            alternateDisplay: true
        }

        const renderOrderDetail = (title, itemCount, lineItemsSubtotal, shippingPrice, estimatedSalesTax, retailDeliveryFee, currentAmountDue, futureInfoIcon, titleClassName, promos, preDiscountTotalPrice, totalText, giftCards, loyaltyRedemption, shippingProtectionOptions) => {
            return (
                <>
                    <div id="titleId" className={titleClassName}>
                        <Typography size={'h4'}>{title}</Typography>
                        {futureInfoIcon}
                    </div>
                    <div id="itemsId" className={"orderDetail"}>
                        <div id="itemsTextId" className={"orderDetailText"}>
                            Items ({itemCount})
                        </div>
                        <div id="itemsSubtotalValueId" className={"orderDetailValue"}>
                            {lineItemsSubtotal}
                        </div>
                    </div>
                    <div id="shippingId" className={"orderDetail"}>
                        <div id="shippingTextId" className={"orderDetailText"}>
                            Shipping
                        </div>
                        <div id="shippingPriceValueId" className={"orderDetailValue"}>
                            {shippingPrice}
                        </div>
                    </div>
                    <div>
                        {shippingProtectionOptions?.map(option => {
                            return (
                                <CardSecondary className="shippingProtectionDisplay" key={option.shippingOptionId}>
                                    <>
                                        <div className="shippingProtectionContents">
                                            <div className="shippingProtectionInformation">
                                                <p data-testid={`${title}_ShippingProtectionOption_SelectedOption_Label`} className="shippingProtectionTitle">{option.title}</p>
                                            </div>
                                            <p className="shippingProtectionPrice" data-testid={`${title}_ShippingProtectionOption_SelectedOption_Price`}>
                                                {option.priceString === '$0.00' ? 'Included' : option.priceString}
                                            </p>
                                        </div>
                                    </>
                                </CardSecondary>
                            )
                        }
                        )}

                    </div>

                    {this.props.showProceedFromShoppingCart !== true ?
                        <Suspense id="discountsId" fallback={<div></div>}><Discounts
                            promotions={{
                                promotions: promos,
                                alternateDisplay: true
                            }}
                            giftCards={{
                                giftCards: giftCards,
                                alternateDisplay: true
                            }}
                            loyaltyRedemption={{
                                loyaltyRedemption: loyaltyRedemption,
                                alternateDisplay: true
                            }}
                            isCustomerInfo={isCustomerInfo}
                        /></Suspense>
                        : false}
                    <div id="salesTaxId" className={"orderDetail"}>
                        <div id="salesTaxTextId" className={"orderDetailText"}>
                            Sales Tax
                        </div>
                        <div id="estimatedSalesTax" className={"orderDetailValue"}>
                            {estimatedSalesTax}
                        </div>
                    </div>
                    {retailDeliveryFee && parseFloat(retailDeliveryFee.slice(1)) !== 0 &&
                        <div id="retailDeliveryFeeId" className={"orderDetail"}>
                            <div id="retailDeliveryFeeTextId" className={"orderDetailText"}>
                                Retail Delivery Fee
                            </div>
                            <div id="retailDeliveryFeeValueId" className={"orderDetailValue"}>
                                {retailDeliveryFee}
                            </div>
                        </div>
                    }
                    <Divider variant="horizontal" />
                    <StrikeThroughPrice id="preDiscountTotalPriceId" preDiscountPrice={preDiscountTotalPrice} currentAmountDue={currentAmountDue} />
                    <div id="orderDetailTotalId" className={"orderDetailTotal"}>
                        <div id="totalTextId" className={"orderDetailTotalText"}>
                            <Typography size={'h4'}> {totalText}</Typography>
                        </div>
                        <div id="totalValueId" className={"orderDetailTotalValue"}>
                            <Typography size={'h4'}>{currentAmountDue}</Typography>
                        </div>
                    </div>

                </>
            )
        }

        const renderClosedDetail = (title, charges) => {
            let titleSection = ""

            if (title !== "")
                titleSection = (
                    <div className={"titleText"}>
                        <Typography>{title}</Typography>
                    </div>
                )

            return (
                <>
                    <div className={"closedDetail"}>
                        {titleSection}
                        <div className={"chargeValue"}>
                            <Typography>{charges}</Typography>
                        </div>
                        
                    </div>
                </>
            )
        }

        const renderOrderDetailsSummary = (orderDetails) => {
            let currentOrderContainer = <span />
            let futureOrderContainer = <span />
            let orderTitle = "Order Details"
            let totalText = "Total"
            if (orderDetails.itemCount > 0 && orderDetails.futureItemCount > 0) {
                orderTitle = "Current Charges"
                totalText = "Current Total"
            }
            let futureChargesInfoIcon = <InfoIcon infoIcon={{
                text: 'You will not be charged for backordered items until they ship. There will be no additional ' +
                    "shipping charges at that time for domestic orders.", svg: < SvgIcon.MoreInformationSvgIcon className={"infoIcon"} />
            }}
                gaCategory={"Checkout"}
                name={"Future Charges"}
            />
            let giftCardsShownInCurrentContainer = false
            if (orderDetails.itemCount > 0) {
                currentOrderContainer = renderOrderDetail(orderTitle, orderDetails.itemCount, orderDetails.lineItemsSubtotal, orderDetails.shippingPrice,
                    orderDetails.estimatedSalesTax, orderDetails.retailDeliveryFee, orderDetails.currentAmountDue, <span />, "orderDetailsTitle", orderDetails.currentPromos, orderDetails.totalPricePreDiscount, totalText, orderDetails.currentGiftCards, orderDetails.currentLoyaltyRedemption, orderDetails.currentShippingProtectionOptions);
            }

            if (orderDetails.futureItemCount > 0) {
                futureOrderContainer = renderOrderDetail("Future Charges", orderDetails.futureItemCount, orderDetails.futureLineItemsSubtotal, orderDetails.futureShippingPrice,
                    orderDetails.futureEstimatedSalesTax, orderDetails.futureRetailDeliveryFee, orderDetails.futurePostDiscountTotalPrice, futureChargesInfoIcon, "futureOrderTitle", orderDetails.futurePromos, orderDetails.futurePreDiscountTotalPrice, "Future Total", orderDetails.futureGiftCards, orderDetails.futureLoyaltyRedemption, orderDetails.futureShippingProtectionOptions);
            }

            return (
                <>
                    {currentOrderContainer}
                    {futureOrderContainer}
                    <LoyaltyPointsPreview />
                    {this.props.showGiftCardPromoForm ?
                        <>
                            <Divider variant="horizontal" />
                            <Suspense fallback={<div></div>}><GiftCardPromoForm isDealer={false} /></Suspense>
                            <Suspense fallback={<div></div>}><LoyaltyCodeForm /></Suspense>
                        </> : false}
                </>
            )
        }

        const renderClosedDetailsSummary = (orderDetails) => {
            let currentOrderContainer = ""
            let futureOrderContainer = ""
            let extraDivider = ""

            if (orderDetails.itemCount > 0) {
                currentOrderContainer = renderClosedDetail(orderDetails.futureItemCount === 0 ? "" : "Current Charges", orderDetails.currentAmountDue)
            }

            if (orderDetails.futureItemCount > 0) {
                extraDivider = <Divider variant="horizontal" />
                futureOrderContainer = renderClosedDetail("Future Charges", orderDetails.futurePostDiscountTotalPrice)
            }

            return (
                <>
                    {currentOrderContainer}
                    {extraDivider}
                    {futureOrderContainer}
                    <LoyaltyPointsPreview />
                </>
            )
        }

        let orderTotalsDisplayStyle = orderDetails.futureItemCount > 0 && orderDetails.itemCount > 0 ? " closedBackorderedTotal" : "";

        let orderSummaryOpenToggleHandle = (
            <>
                <span>Hide Order Summary</span>
                <SvgIcon.DownArrowSvgIcon className="invert" />
            </>
        )
        let orderSummaryClosedToggleHandle = (
            <>
                <span>Show Order Summary</span>
                <SvgIcon.DownArrowSvgIcon className="down" />
            </>
        )
        let orderSummaryToggle = {
            openToggleHandle: orderSummaryOpenToggleHandle,
            closedToggleHandle: orderSummaryClosedToggleHandle,
            toggleableContent: renderOrderDetailsSummary(orderDetails),
            isActive: this.state.isOrderSummaryToggleActive
        }
        return (
            <CardPrimary id="orderSummaryContainer" >
                <Typography as={'h2'} className="orderSummaryHeading">{orderHeaderTitle}</Typography>
                <div className="orderSummaryToggle">
                    <Toggle onClick={this.handleToggle} toggle={orderSummaryToggle}></Toggle>
                </div>
                <div onClick={this.handleToggle} className={"closedTotal" + orderTotalsDisplayStyle}>{!this.state.isOrderSummaryToggleActive ? renderClosedDetailsSummary(orderDetails) : ""}</div>
                {this.props.showProceedFromShoppingCart ?
                    <>
                        <Divider variant="horizontal" />
                        <Button variant={'confirmation'} className="proceedToCheckoutBttn" onClick={() => { this.props.handleContinueToCheckoutClick(orderDetails.lineItemsSubtotal) }}>
                            <SvgIcon.LockSvgIcon />
                            Proceed to Secure Checkout
                        </Button>
                        {this.props.showPaypalExpressButton ?
                            <Suspense fallback={<div></div>}><PayPalExpressButton isMvcRedirect={true} /></Suspense> : false
                        }
                    </> : false}
            </CardPrimary>
        )
    }
}

function StrikeThroughPrice(props) {
    let { preDiscountPrice, currentAmountDue } = props

    if ((preDiscountPrice) && (currentAmountDue !== preDiscountPrice)) {
        return (<Typography as={'h6'} className={"strikeThroughPrice"}>preDiscountPrice</Typography>)
    }
    return false
}

const handleContinueToCheckoutClick = (orderPrice) => (dispatch) => {
    dispatch(enableLoader())
    window.location.href = "/buy"

    if (!navigator.globalPrivacyControl && !document.cookie.includes('EnableGpc=')) {
        dispatch(sendGoogleAnalyticsEvent('Cart', 'Advanced To Checkout', 'Order Summary'))
    }

    dispatch(enterCheckout(orderPrice))
}

const mapDispatchToProps = {
    handleContinueToCheckoutClick
};

const mapStateToProps = (state) => {

    return {
        orderDetails: state.orderDetails
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderDetails));
