import React, {useState} from 'react'
import {connect} from 'react-redux';
import './CheckoutHeader.css';
import {CartSvgIcon} from 'Components/SvgIcon/SvgIcon'
import Modal from "Components/Modal/Modal"
import {CardPrimary} from 'Components/Card/Card'
import Button from 'Components/Button/Button'
import Typography from 'Components/Typography/Typography'
import {sendGoogleAnalyticsEvent} from 'Actions/googleAnalyticsAction'

const CheckoutHeader = (
    {
        transactionId,
        orderDetails,
        isEscapeModalEnabled,
        sendGoogleAnalyticsEvent
    }
) => {

    let cartCount;
    const [escapePromptShown, toggleEscapePrompt] = useState(false)
    let ariaCartString = "Shopping Cart";
    if (orderDetails) {
        if (orderDetails.futureItemCount > 0 && orderDetails.itemCount > 0) {
            cartCount = orderDetails.itemCount + orderDetails.futureItemCount
        } else if (orderDetails.futureItemCount > 0 && orderDetails.itemCount < 1) {
            cartCount = orderDetails.futureItemCount
        }
        else if (orderDetails.itemCount > 0) {
            cartCount = orderDetails.itemCount
        }

        ariaCartString = "Shopping cart contains " + (cartCount ? cartCount : "no") + (cartCount && cartCount === 1 ? " item" : " items");

        if (cartCount > 9) {
            cartCount = "9+"
        }
    }
    let escapePrompt
    let redirectFunction = isEscapeModalEnabled ? () => toggleEscapePrompt(true) : () => cartRedirect()
    if (escapePromptShown) {
        let modalContent = (
            <CardPrimary className="escapePromptModal">
                <Typography size={'h2'}>There's parts in your cart!</Typography>
                <Typography size={'h4'}>Want to complete your order before you go?</Typography>

                <Button
                    variant={'tertiary'}
                    className="returnToCart"
                    onClick={
                        () => {
                            if(!navigator.globalPrivacyControl && !document.cookie.includes('EnableGpc=')) {
                                sendGoogleAnalyticsEvent("Checkout", "Escape Prompt", "Return to Cart")
                            }
                            cartRedirect()
                        }
                    }
                > Return to Cart</Button >
                <Button
                    variant={'primary'}
                    title={"Stay in checkout"}
                    onClick={() => {
                        if(!navigator.globalPrivacyControl && !document.cookie.includes('EnableGpc=')) {
                            sendGoogleAnalyticsEvent("Checkout", "Escape Prompt", "Stay in Checkout")
                        }
                        toggleEscapePrompt(false)
                    }}
                >Continue Checkout</Button>
            </CardPrimary >
        )
        escapePrompt = <Modal content={modalContent} />
    }
    return (<header id="header">
        <div id="headerPageWidth">
            <a id="logo" href="javascript:void(0)" onClick={redirectFunction} title="Speedway Motors Home Page" aria-label="Speedway Motors Home Page" role="link">
                <img className="logo" src="//content.speedwaymotors.com/OtherImages/SpeedwayMotors_Logo_Full_RGB2.svg" alt="Speedway Motors Logo" />
            </a>
            <a id="cartContainer" href="javascript:void(0)" onClick={redirectFunction} aria-label={ariaCartString}>
                <CartSvgIcon />
                <span className="headerIconText">Cart</span>
                {cartCount ?
                    <div className="cartCount">
                        {cartCount}
                    </div>
                    : false}

            </a>
        </div>
        {escapePrompt}
    </header>)
}

function cartRedirect() {
    window.location.href = "/shoppingcart"
}
const mapDispatchToProps = {
    sendGoogleAnalyticsEvent
}
const mapStateToProps = (state) => {
    return {
        transactionId: state.transaction.transactionId,
        orderDetails: state.orderDetails,
        isEscapeModalEnabled: state.featureFlagContainer.isEscapeModalEnabled
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutHeader)
