import React, { useState } from 'react';
import { connect } from 'react-redux';
import { TextboxDropdown } from 'Components/Textbox/DeprecatedTextbox';
import Typography from 'Components/Typography/Typography';
import { requestValidatedSmartyStreetAddress, requestSmartyStreetAddresses } from 'Actions/checkoutTransactionAction';
import { SMARTY_STREETS_AUTH_ID } from 'Constants/Constants';

const SmartyStreetsAutoComplete = (
    {
        featureFlagContainer,
        updateFormAction,
        requestValidatedSmartyStreetAddress,
        textboxDropdown,
        formAddress
    }
) => {
    const [storedAutoCompleteQueryParam, updateStoredAutoCompleteQueryParam] = useState('');
    const [smartyStreetsRequests, updateSmartyStreetsRequests] = useState([]);
    const [autoCompleteAddresses, updateAutoCompleteAddresses] = useState([]);
    const [focusedSuggestion, updateFocusedSuggestion] = useState(0);
    const [isFirstFocusedSuggestion, updateIsFirstFocusedSuggestion] = useState(true);

    const timeout = (ms, promise) => {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                reject(new Error("timeout"))
            }, ms)
            promise.then(resolve, reject)
        })
    }

    const getSmartyStreetAddresses = (addressToComplete, signal) => {

        if (!addressToComplete) {
            return new Promise((resolve, reject) => {return []})
        }

        const isSmartyStreetsNewApiVersionEnabled = true

        const smartyStreetUrl = isSmartyStreetsNewApiVersionEnabled ?
            "https://us-autocomplete-pro.api.smartystreets.com/lookup?key=" + SMARTY_STREETS_AUTH_ID + "&max_results=5" + "&prefer_ratio=75" + "&prefer_geolocation=city" + "&search=" :
            "https://us-autocomplete.api.smartystreets.com/suggest?auth-id=" + SMARTY_STREETS_AUTH_ID + "&suggestions=5" + "&prefer_ratio=1" + "&prefix=";


        const url = smartyStreetUrl + addressToComplete
        return timeout(4000, fetch(url,
                {
                    method: "GET",
                    signal: signal
                }))
            .then(response => {
                return response.json()
            })
            .then(json => {
                return json
            })
            .catch(error => {
                console.log('SmartyStreets Timeout')
            })
    }

    const validateSmartyAddresses = (e) => {

        let item = undefined;

        if (e.target.dataset) {
            item = e.target.dataset.item;
        }
        if (!item && e.target.parentElement.dataset) {
            item = e.target.parentElement.dataset.item;
        }
        if (!item && e.target.parentElement.parentElement.dataset) {
            item = e.target.parentElement.parentElement.dataset.item;
        }

        const addressToValidate = JSON.parse(item)
        const data = {
            street: addressToValidate.street,
            city: addressToValidate.city,
            state: addressToValidate.state
        }

        requestValidatedSmartyStreetAddress(data, updateFormAction, formAddress)
    }

    const handleFetchAutoCompleteAddresses = (e) => {
        let controller
        let signal

        // This is in a try catch becasue IE does not support Abort Controllers. In IE, it just sets the controller and the signal to null and does not abort if another input is made mid fetch.
        try {
            controller = new AbortController()
            signal = controller.signal
        } catch (error) {
            controller = null
            signal = null
        }

        var addressToComplete = e.target.value

        updateStoredAutoCompleteQueryParam(addressToComplete);

        const addressSuggestions = getSmartyStreetAddresses(addressToComplete, signal)

        let requests = smartyStreetsRequests

        // if the last request promise is fulfilled before any other promises are made then it turns to one
        // here we reset this back to an array to avoid js console errors
        if (smartyStreetsRequests === 1) {
            requests = []
        }

        addressSuggestions.currentAutoCompleteQueryParam = storedAutoCompleteQueryParam;
        // add request to the list
        updateSmartyStreetsRequests(requests.push(addressSuggestions));


        // this will cause only the last query's data to update the dropdown list
        addressSuggestions.then((addressSuggestions) => {
            if (addressToComplete && addressSuggestions != null) {

                // make sure we have suggestions and that there is at least one
                if (addressSuggestions.suggestions && addressSuggestions.suggestions.length > 0) {
                    const autoCompleteAddresses = addressSuggestions.suggestions.map((suggestion, index) => {
                        const content = suggestion.text ? suggestion.text : '' + suggestion.street_line + ', ' + suggestion.city + ' ' + suggestion.state;

                        let matchingStringIndex = 0;
                        for (let i = 0; i < content.length; i++) {
                            if (addressToComplete.charAt(i) !== content.charAt(i)) {
                                break;
                            }
                            matchingStringIndex = i;
                        }

                        const matchingString = matchingStringIndex !== content.length ? content.substring(0, matchingStringIndex + 1) : content.substring(0, content.length);
                        const suggestionString = matchingStringIndex !== content.length ? content.substring(matchingStringIndex + 1, content.length) : '';

                        const contentHtml = (
                            <span className={'dropdownItem'}
                                key={matchingStringIndex}
                                tabIndex={'0'}
                                data-street={suggestion.street_line}
                                data-city={suggestion.city}
                                data-state={suggestion.state}>
                                <Typography>
                                    {matchingString}
                                </Typography>
                                <Typography bold>
                                    {suggestionString}
                                </Typography>
                            </span>
                        );

                        return {
                            content: contentHtml,
                            data: JSON.stringify({
                                street: suggestion.street_line,
                                city: suggestion.city,
                                state: suggestion.state
                            })
                        }
                    })
                    updateAutoCompleteAddresses(autoCompleteAddresses);
                } else {
                    updateAutoCompleteAddresses([]);
                }
            }
        });

        // kill the requests made before this request
        let requestKilled = false;
        requests.forEach((request) => {
            if (storedAutoCompleteQueryParam !== request.currentAutoCompleteQueryParam) {
                requestKilled = true
                if (controller) {
                    controller.abort()
                }
            }
        })

        if (requestKilled || requests.length === 0) {
            updateSmartyStreetsRequests([])
        }
    }

    const arrowKeysEvent = (e) => {
        e = e || window.event;
        const key = (typeof e.which == "number") ? e.which : e.keyCode;
        if (key === 8) {
            // backspace key
            updateFocusedSuggestion(0);
            updateIsFirstFocusedSuggestion(true);

            const suggestions = document.getElementsByClassName('dropdownItem');

            Array.from(suggestions).forEach((suggestion) => {
                suggestion.classList.remove('dropdownItemActive');
            });

            if (!e.target.value || e.target.value.length === 1) {
                updateAutoCompleteAddresses([]);
            }
        }
        if (key === 40) {
            // arrow key down
            e.preventDefault();
            let focusedSuggestionIndex = focusedSuggestion;
            if(autoCompleteAddresses.length < 1){
                return
            }

            if (focusedSuggestion !== autoCompleteAddresses.length - 1 && !isFirstFocusedSuggestion) {
                updateFocusedSuggestion(focusedSuggestion + 1);
                focusedSuggestionIndex = focusedSuggestion + 1;
            }
            if (isFirstFocusedSuggestion) {
                updateIsFirstFocusedSuggestion(false);
            }
            const suggestions = document.getElementsByClassName('dropdownItem')

            Array.from(suggestions).forEach((suggestion) => {
                suggestion.classList.remove('dropdownItemActive');
            });

            suggestions[focusedSuggestionIndex].classList.add('dropdownItemActive');

        } else if (key === 38) {
            // arrow key up
            e.preventDefault();
            let focusedSuggestionIndex = focusedSuggestion;
            if(autoCompleteAddresses.length < 1){
                return
            }

            if (focusedSuggestion !== 0 && !isFirstFocusedSuggestion) {
                updateFocusedSuggestion(focusedSuggestion - 1);
                focusedSuggestionIndex = focusedSuggestion - 1;
            }
            if (isFirstFocusedSuggestion) {
                updateIsFirstFocusedSuggestion(false);
            }
            const suggestions = document.getElementsByClassName('dropdownItem');

            Array.from(suggestions).forEach((suggestion) => {
                suggestion.classList.remove('dropdownItemActive');
            });

            suggestions[focusedSuggestionIndex].classList.add('dropdownItemActive');

        } else if ((key === 13 || key === 9) && !isFirstFocusedSuggestion) {
            // enter key
            e.preventDefault();
            const desiredSuggestion = document.getElementsByClassName('dropdownItem')[focusedSuggestion];

            const data = {
                street: desiredSuggestion.dataset.street,
                city: desiredSuggestion.dataset.city,
                state: desiredSuggestion.dataset.state
            };

            requestValidatedSmartyStreetAddress(data, updateFormAction, formAddress);
            updateAutoCompleteAddresses([]);
            e.target.blur();
            e.target.focus();
        } else if (key === 27) {
            // escape key
            updateFocusedSuggestion(0);
            updateIsFirstFocusedSuggestion(true);
            updateAutoCompleteAddresses([]);
        }
    }


    return (
        <TextboxDropdown
            type={textboxDropdown.type}
            textbox={textboxDropdown.textbox}
            name={textboxDropdown.name}
            onChange={(e) => {
                textboxDropdown.onChange(e);
                handleFetchAutoCompleteAddresses(e);
            }}
            onKeyDown={arrowKeysEvent}
            formName={textboxDropdown.formName}
            propName={textboxDropdown.propName}
            errorMessages={textboxDropdown.errorMessages}
            warningMessages={textboxDropdown.warningMessages}
            autocomplete={textboxDropdown.autocomplete}
            dropdownItems={autoCompleteAddresses}
            dropdownItemOnClick={validateSmartyAddresses}
            onBlur={(e) => {
                textboxDropdown.onBlur(e);
                updateFocusedSuggestion(0);
            }}
            onFocus={(e) => {
                textboxDropdown.onFocus(e);
                updateIsFirstFocusedSuggestion(true);
            }}
        />
    )
}


const mapDispatchToProps = {
    requestValidatedSmartyStreetAddress,
    requestSmartyStreetAddresses
}

const mapStateToProps = (state) => {
    return {
        featureFlagContainer: state.featureFlagContainer
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SmartyStreetsAutoComplete)